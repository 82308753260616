/* body {
  background-color: #dfe0dc;
} */

.home {
  max-width: 100vw;
  height: 100vh;
  margin: 0px;
  background-image: url(https://media.giphy.com/media/blSTtZehjAZ8I/giphy.gif);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  max-width: 100vw;
  margin: 0px;
  border-radius: 0px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  max-width: 100vw;
  margin: 0px;
  border-radius: 0px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.error .page-wrapper {
  background-color: #dfe0dc;
  margin: 5% 0px;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  border-radius: 15px;
}

.home .page-wrapper {
  background-color: #083d77;
  color: white;
  padding-top: 2rem;
}

.home .page-wrapper p {
  width: 80%;
  text-align: center;
}

.main .page-wrapper {
  background-color: #dfe0dc;
  margin: 5% 0px;
}

header {
  background-color: #083d77;
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60px;
  position: sticky;
  top: 0px;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  overflow: hidden;
}

.header-inner * {
  margin: 1rem;
}

.logo {
  margin: 0.5rem;
  max-width: 80px;
  height: auto;
}
.logo-small {
  margin: 0.5rem;
  max-width: 40px;
  height: auto;
}

.configuration-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.configuration-wrapper > * {
  width: 80%;
  margin-top: 2rem;
}

.configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stickytop {
  position: sticky;
  top: 60px;
  /* background-color: #5F634F; */
  background-color: #083d77;
  width: 100%;
  display: flex;
  justify-content: center;
}

.playlist-summary {
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.1)
}

.playlist-meta {
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, rgba(0,0,0,0) 18%, rgba(0,0,0,.4) 82%);
}

.playlist-cover {
  max-width: 100px;
}

.playlist-name {
  color: white;
  margin: 1rem;
}

.playlist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.playlist-item-left {
  display: flex;
  align-items: center;
}

.song-and-artist {
  display: flex;
  flex-direction: column;
}

.song-and-artist p {
  margin: 0px;
}

.song-name {
  font-weight: bold;
}

.episode-and-show {
  display: flex;
  flex-direction: column;
}

.episode-and-show p {
  margin: 0px;
}

.episode-name {
  font-weight: bold;
}

.song-tag {
  border: 2px solid #083d77;
  color: #083d77;
  margin-right: 0.5rem;
  padding: 0.35rem;
  border-radius: 4px;
  font-size: 0.6rem;
}

.episode-tag {
  border: 2px solid #5F634F;
  color: #5F634F;
  margin-right: 0.5rem;
  padding: 0.35rem;
  border-radius: 4px;
  font-size: 0.6rem;
}

.episode-img {
  width: 50px;
  margin-right: 0.5rem;
}

.episode-name {
  font-weight: bold;
}

.spotify-attribution {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.spotify-logo {
  min-width: 70px;
  max-width: 140px;
  margin: 21px;
}

@media screen and (max-width: 480px) {
  :root {
    font-size: 12px;
  }
  .App {
    padding: 0px;
  }
  body {
    background-color: #dfe0dc;
  }
  .page-wrapper {
    border-radius: 0px;
    margin: 0px;
  }
  .home {
    background-position: center;
    align-items: flex-end;
  }
  .home .page-wrapper {
    width: 100%;
    margin-bottom: 10vh;
  }
  .main {
    background-color: #dfe0dc;
  }
  .main .page-wrapper {
    margin: 0px;
    width: 100%;
  }
  header {
    border-radius: 0px;
  }
}